<template>
  <div id="app">
    <keep-alive>
      <router-view :key="$route.path" />
    </keep-alive>
  </div>
</template>

<script>
import '@/css/main.scss'

export default {
  name: "App",
  created () {
    let debug = this.$global.GetQueryString('db')
    if (debug) {
      this.$store.commit('setDebug', true)
    }
  },
};
</script>
